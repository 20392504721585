<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar title="全部订单" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hed_lis">
      <div class="hhcc" @click="qiehuan(-1)">
        全部
        <div class="hua" v-if="active == '-1'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(0)">
        待付款
        <div class="hua" v-if="active == '0'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(1)">
        待预约
        <div class="hua" v-if="active == '1'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(2)">
        待安装
        <div class="hua" v-if="active == '2'"></div>
      </div>
    </div>
    <div style="height: 100%;background: rgb(235, 237, 240)" class="ordhed">
      <div class="ord_lis" style="background: rgb(235, 237, 240);">
        <div
          class="order_list"
          v-for="(item,index) in list"
          :key="index"
          @click="dindanxiangqin(item)"
        >
          <div class="ordrt_h1">
            <div class="left">订单编号：{{item.orderNumber}}</div>
            <div class="right">
              <span style="vertical-align: middle" v-if="item.status == -1">已取消</span>
              <span v-if="item.status == 0">待付款</span>
              <span v-if="item.status == 1">待预约</span>
              <span v-if="item.status == 2">待安装</span>
              <span v-if="item.status == 4">已完成</span>
              <van-icon
                class="shanchu"
                @click.stop="shanchuB(item)"
                v-if="item.status == -1"
                style="font-size: 14px;"
                name="delete"
              />
            </div>
          </div>
          <div class="ordrt_h2">
            <img class="order_img left" :src="item.attrImg" alt />
            <div class="right order_name">
              <div class="biaoti_ord">{{item.serviceName}}</div>
              <div class="jiance">免费常规检测服务</div>
              <div class="jianshu">
                <span class="left" style="color: #999;">共{{item.num}}件</span>
                <span class="right" v-if="item.orderTotal">￥{{item.orderTotal}}</span>
              </div>
            </div>
          </div>
          <div class="bot">
            <div
              class="zhifu"
              v-if="item.status == 0 && item.type != '2'"
              @click.stop="zhifupay(item)"
            >立即支付</div>
            <div
              class="zhifu"
              v-if="item.status == 0 && item.type == '2'"
              @click.stop="miaoshatijiao(item)"
            >立即支付</div>
            <div class="zhifu" v-if="item.status == 1" @click.stop="yuyue(item)">立即预约</div>
          </div>
        </div>
      </div>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import userModel from "../../api/user";
import utils from "../../assets/utils";
import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: "-1",
      isWxMini: ""
    };
  },
  methods: {
    qiehuan(row) {
      this.active = row;
      this.onLoad();
    },
    zhifupay(item) {
      var _this = this;
      var lis = {
        id: item.id,
        orderNumber: item.orderNumber,
        totalMoney: item.orderTotal,
        type: item.type,
        gid: this.shangjia.id
      };
      console.log(lis);
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 1; //1:普通订单，2秒杀订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/payputong/payputong?payDataStr=" + payDataStr // 微信小程序调用支付的页面
        });
      } else {
        userModel.pay(lis).then(res => {
          if (res.code == 200) {
            console.log(WeixinJSBridge);
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },
    miaoshatijiao(item) {
      var _this = this;
      // userModel.seckillOrder().then(res => {
      // if (res.code == 200) {
      var lis = {
        id: item.id,
        orderNumber: item.orderNumber,
        totalMoney: item.orderTotal,
        type: item.type,
        gid: this.shangjia.id
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 2; //1:普通订单，2秒杀订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/payputong/payputong?payDataStr=" + payDataStr // 微信小程序调用支付的页面
        });
      } else {
        userModel.pay(lis).then(res => {
          if (res.code == 200) {
            console.log(res);
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }

      // }
      // });
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("支付成功");
            setTimeout(() => {
              vm.onLoad();
            }, 900);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    //删除订单
    shanchuB(item) {
      const data = {
        id: item.id,
        type: item.type
      };
      orderModel
        .shanchuorder(data)
        .then(e => {
          // loading.clear();
          this.$toast("删除成功");
          setTimeout(() => {
            this.onLoad();
          }, 800);
        })
        .catch(() => loading.clear());
    },
    //预约
    yuyue(item) {
      sessionStorage.removeItem("station");
      if (item.appointmentIds != null) {
        var station = {
          appointmentId: item.appointmentIds,
          itemName: [],
          comboItemList: []
        };
        sessionStorage.setItem("station", JSON.stringify(station));
      }

      window.location.href =
        "/order_yuyue?orderNumber=" +
        item.orderNumber +
        "&carNo=" +
        item.carNo +
        "&type=" +
        item.type +
        "&rowtype=" +
        0;
    },
    dindanxiangqin(item) {
      window.location.href =
        "/order_details?id=" +
        item.id +
        "&status=" +
        item.status +
        "&row=" +
        this.active +
        "&type=" +
        item.type +
        "&rowtype=" +
        0;
      // this.$router.push({
      //   path: "order_details",
      //   query: {
      //     id: item.id,
      //     status: item.status,
      //     row: this.active
      //   }
      // });
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        status: this.active
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .dingdanlist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    pushHistory() {
      //写入空白历史路径
      let state = {
        title: "title",
        url: "#"
      };
      window.history.pushState(state, state.title, state.url);
    },

    backziding() {
      this.pushHistory();
      window.addEventListener(
        "popstate",
        function(e) {
          location.href = "/mine";
        },
        false
      );
    },
    back() {
      // this.$router.go(-1);
      this.$router.push({
        path: "mine"
        // query: {
        //   status: this.status
        // }
      });
    }
  },
  created() {
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    // this.active = this.$route.query.status;
    this.active = utils.getUrlKey("status");
    // if(utils.getUrlKey("status")){

    // }

    this.onLoad();
    // console.log(utils.getUrlKey("status"));
  },
  mounted() {
    this.backziding();
  }
};
</script>

<style lang="less" scoped>
.order_list {
  margin: 0 10px;
  background: #fff;
  padding: 0 10px;
  border-radius: 5px;
  overflow: hidden;
}
.ordrt_h1 {
  overflow: hidden;
  color: #666;
  font-size: 12px;
  padding-top: 10px;
}
.zhifu {
  // display: inline-block;
  font-size: 12px;
  padding: 0 6px;
  color: #b22222;
  border: 1px solid #b22222;
  border-radius: 12px;
  float: right;
  line-height: 20px;
  height: 22px;
  font-weight: bold;
}
.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
}
.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 25%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}
.hua {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 5px;
  width: 46px;
  text-align: center;
  margin-left: -23px;
  height: 3px;
  z-index: 1;
  background-color: #ee0a24;
  border-radius: 1.5px;
}
.bot {
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}
.biaoti_ord {
  height: 32px;
}
.ordrt_h2 {
  //   height: 65px;
  margin-top: 15px;
  padding-bottom: 20px;
  overflow: hidden;
}
.ordhed {
  padding-top: 100px;
}

.order_img {
  height: 65px;
  width: 65px;
}
.shanchu {
  margin-left: 8px;
  font-size: 16px;
  vertical-align: middle;
}
.order_name {
  height: 65px;
  position: relative;
  font-size: 12px;
  width: 78%;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 16px;
}
.jiance {
  min-height: 11px;
  margin-top: 2px;
  font-size: 7px;
  border: 1px solid #4169e1;
  display: inline-block;
  color: #4169e1;
  line-height: 11px;
  border-radius: 2px;
}
.ord_lis .order_list:nth-of-type(n + 2) {
  margin-top: 10px;
}

.jianshu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  //    margin-top: 4px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>
